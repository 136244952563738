import { defineStore } from "pinia";
import { Opportunity } from "@/types/opportunity";
import { Track } from "../types/track";
import OpportunitySubmissionService from "../services/opportunity-submission-service";
import { OpportunitySubmission } from "@/types/opportunity-submission";
import { ProAction } from "@/types/playlists";
import { Sort } from "@/types/data-table";
import { handleError } from "@/utils/error";
import config from "@/config";
import { WritableComputedRef } from "nuxt/dist/app/compat/capi";
import AnalyticsService from "@/services/analytics-service";

const opportunitySubmissionService = new OpportunitySubmissionService(
  "opportunity-submission",
);
const analyticsService = new AnalyticsService("Analytics");

export const useOpportunitySubmissionStore = defineStore(
  "opportunity-submission",
  {
    state: (): {
      opportunity: Opportunity;
      selectedTrack?: Track;
      opportunitySubmissions: OpportunitySubmission[];
      opportunitySubmissionsCount: number;
      tmpOpportunitySubmissionsCount: number;
      infiniteScrollPageSize: number;
      hasMore: boolean;
      selectedOpportunitySubmission?: OpportunitySubmission;
      isLoading: boolean;
      message: string;

      pageSize: number;
      page: number;
      total: number;
      sort?: Sort;

      previousQuery: string;
      scrollPosition: number;
    } => ({
      opportunity: {} as Opportunity,
      selectedTrack: undefined,
      opportunitySubmissions: [],
      opportunitySubmissionsCount: 0,
      tmpOpportunitySubmissionsCount: 0,
      infiniteScrollPageSize: 50,
      hasMore: true,
      selectedOpportunitySubmission: undefined,
      isLoading: false,
      message: "",

      pageSize: 5,
      page: 0,
      total: 0,
      sort: undefined,

      previousQuery: "",
      scrollPosition: 0,
    }),
    actions: {
      clearAll() {
        this.opportunity = {} as Opportunity;
        this.selectedTrack = undefined;
        this.opportunitySubmissions = [];
        this.opportunitySubmissionsCount = 0;
        this.hasMore = true;
        this.selectedOpportunitySubmission = undefined;
        this.isLoading = false;
        this.message = "";
        this.pageSize = 5;
        this.page = 0;
        this.total = 0;
        this.sort = undefined;
        this.previousQuery = "";
        this.scrollPosition = 0;
      },

      async fetchMyOpportunitySubmissions() {
        try {
          const submissions =
            await opportunitySubmissionService.listMyOpportunitySubmissions(
              {
                start: this.page * this.pageSize,
                length: this.pageSize,
              },
              this.sort,
            );
          this.opportunitySubmissions = submissions.data;
          this.total = submissions.total;
        } catch (error) {
          throw handleError(error);
        }
      },

      selectNextById(id: string) {
        const index = this.opportunitySubmissions.findIndex(
          (submission) => submission._id === id,
        );
        if (index === -1) {
          this.selectedOpportunitySubmission = undefined;
          return;
        }

        if (index === this.opportunitySubmissions.length - 1) {
          this.selectedOpportunitySubmission = undefined;
          return;
        }

        this.selectedOpportunitySubmission =
          this.opportunitySubmissions[index + 1];
      },

      async submitOpportunity() {
        try {
          await opportunitySubmissionService.submitOpportunity({
            opportunityId: this.opportunity._id,
            trackId: this.selectedTrack?._id || "",
            message: this.message,
          });
          this.clear();
        } catch (error) {
          throw handleError(error);
        }
      },

      async fetchOpportunitySubmissionById(id: string) {
        try {
          const opportunitySubmission =
            await opportunitySubmissionService.getById(id);
          this.selectOpportunitySubmission(opportunitySubmission);
        } catch (error) {
          throw handleError(error);
        }
      },

      async getOpportunitySubmissionCountByOpportunityId(query: any) {
        try {
          const count =
            await opportunitySubmissionService.getCountByOpportunityId(
              this.opportunity._id,
              query,
            );
          this.tmpOpportunitySubmissionsCount = count;
        } catch (error) {
          throw handleError(error);
        }
      },

      async loadMoreOpportunitySubmissions(query: any) {
        try {
          this.opportunitySubmissionsCount =
            this.tmpOpportunitySubmissionsCount;

          if (this.isLoading) {
            return;
          }

          this.isLoading = true;
          if (!this.hasMore) {
            return;
          }
          const submissions =
            await opportunitySubmissionService.listByOpportunityId(
              this.opportunity._id,
              {
                start: this.total,
                length: this.infiniteScrollPageSize,
                ...query,
              },
            );
          this.opportunitySubmissions =
            this.opportunitySubmissions.concat(submissions);
          this.total += this.infiniteScrollPageSize;

          if (submissions.length < this.infiniteScrollPageSize) {
            this.hasMore = false;
          }
        } catch (error) {
          this.hasMore = false;
          throw handleError(error);
        } finally {
          this.isLoading = false;
        }
      },

      removeOpportunitySubmissionById(id: string) {
        this.opportunitySubmissions = this.opportunitySubmissions.filter(
          (submission) => submission._id !== id,
        );
        this.opportunitySubmissionsCount -= 1;
      },

      async save(opportunitySubmissionId: string, progress: number) {
        try {
          if (this.selectedOpportunitySubmission) {
            this.selectedOpportunitySubmission.saved = true;
          }
          await opportunitySubmissionService.proAction(
            opportunitySubmissionId,
            ProAction.SAVE,
            progress,
          );
        } catch (error) {
          throw handleError(error);
        }
      },

      async unSave(
        opportunitySubmissionId: string,
        remove: boolean,
        progress: number,
      ) {
        try {
          if (this.selectedOpportunitySubmission) {
            this.selectedOpportunitySubmission.saved = false;
          }
          await opportunitySubmissionService.proAction(
            opportunitySubmissionId,
            ProAction.UNSAVE,
            progress,
          );

          if (remove) {
            this.removeOpportunitySubmissionById(opportunitySubmissionId);
          }
        } catch (error) {
          throw handleError(error);
        }
      },

      async archive(opportunitySubmissionId: string, progress: number) {
        try {
          await opportunitySubmissionService.proAction(
            opportunitySubmissionId,
            ProAction.ARCHIVE,
            progress,
          );

          this.removeOpportunitySubmissionById(opportunitySubmissionId);
        } catch (error) {
          throw handleError(error);
        }
      },

      async unArchive(opportunitySubmissionId: string, progress: number) {
        try {
          await opportunitySubmissionService.proAction(
            opportunitySubmissionId,
            ProAction.UNARCHIVE,
            progress,
          );

          this.removeOpportunitySubmissionById(opportunitySubmissionId);
        } catch (error) {
          throw handleError(error);
        }
      },

      async like(opportunitySubmissionId: string, progress: number) {
        try {
          await opportunitySubmissionService.proAction(
            opportunitySubmissionId,
            ProAction.LIKE,
            progress,
          );
        } catch (error) {
          throw handleError(error);
        }
      },
      async superlike(opportunitySubmissionId: string, progress: number) {
        try {
          await opportunitySubmissionService.proAction(
            opportunitySubmissionId,
            ProAction.SUPERLIKE,
            progress,
          );
        } catch (error) {
          throw handleError(error);
        }
      },

      async submitMessage(
        submissionId: string,
        message: string,
        progress: number,
      ) {
        try {
          await opportunitySubmissionService.submitMessage(
            submissionId,
            message,
            progress,
          );
        } catch (error) {
          throw handleError(error);
        }
      },

      async loadMoreUntilFindOpportunitySubmission(id: string) {
        let found = this.opportunitySubmissions.find(
          (submission) => submission._id === id,
        );
        while (this.hasMore && !found) {
          await this.loadMoreOpportunitySubmissions({});

          found = this.opportunitySubmissions.find(
            (submission) => submission._id === id,
          );
        }
      },

      setOpportunity(opportunity: Opportunity): void {
        this.resetSubmissions();
        this.opportunity = opportunity;
      },

      selectOpportunitySubmission(
        opportunitySubmission: OpportunitySubmission,
      ): void {
        this.selectedOpportunitySubmission = opportunitySubmission;
      },

      selectFirstOpportunitySubmission(): void {
        this.selectedOpportunitySubmission = this.opportunitySubmissions[0];
        if (this.selectedOpportunitySubmission) {
        }
      },

      setSelectedTrack(track: Track): void {
        this.selectedTrack = track;
      },

      clearSelectedTrack(): void {
        this.selectedTrack = undefined;
      },

      setMessage(message: string): void {
        this.message = message;
      },

      clear() {
        this.opportunity = {} as Opportunity;
        this.selectedTrack = undefined;
        this.message = "";
      },

      resetSubmissions() {
        this.total = 0;
        this.opportunitySubmissionsCount = 0;
        this.opportunitySubmissions = [];
        this.hasMore = true;
        this.selectedOpportunitySubmission = undefined;
        this.isLoading = false;
      },
      async play(
        playlistSubmissionId: string,
        progress: number,
      ): Promise<boolean> {
        try {
          await opportunitySubmissionService.proAction(
            playlistSubmissionId,
            ProAction.PLAY,
            progress,
          );
          return true;
        } catch (error) {
          throw handleError(error);
        }
      },

      async getOpportunitySubmissions(
        query: WritableComputedRef<{
          [key: string]: string;
        }>,
      ): Promise<{ pending: globalThis.Ref<boolean> }> {
        try {
          const router = useRouter();
          let authToken;

          if (!process.server) {
            authToken = localStorage.getItem("authToken");
          }

          const { pending } = await useFetch<OpportunitySubmission[]>(
            `${config("apiBaseUrl")}/opportunity-submission/by-opportunity/${
              this.opportunity._id
            }`,
            {
              query,
              key: `/opportunity-submission/by-opportunity/${
                this.opportunity._id
              }/${JSON.stringify(query.value)}`,
              watch: [() => query],
              onRequest: () => {
                if (
                  router.currentRoute.value.name !==
                  "my-opportunities-opportunitySlug"
                ) {
                  return;
                }

                if (
                  ((query.value as { start?: string }).start || "0") === "0"
                ) {
                  this.page = 0;
                  this.opportunitySubmissions = [];
                  this.opportunitySubmissionsCount =
                    this.tmpOpportunitySubmissionsCount;
                }
              },
              onResponse: ({ response }) => {
                if (
                  router.currentRoute.value.name !==
                  "my-opportunities-opportunitySlug"
                ) {
                  return;
                }

                if (
                  this.previousQuery == JSON.stringify(query.value) &&
                  this.opportunitySubmissions.length
                ) {
                  return;
                }

                this.previousQuery = JSON.stringify(query.value);

                this.opportunitySubmissions =
                  this.opportunitySubmissions.concat(response._data || []);
                const { start, length, ...filter } = query.value;
                if (Object.keys(filter).length)
                  analyticsService.trackProAppliedFilter(
                    filter,
                    this.opportunitySubmissions.length,
                    "Opportunity",
                  );
              },
              headers: {
                ...(authToken && { Authorization: `Bearer ${authToken}` }),
              },
            },
          );

          return { pending };
        } catch (error) {
          throw handleError(error);
        }
      },

      async getOpportunitySubmissionCount(
        query: Ref<Object>,
      ): Promise<{ pending: globalThis.Ref<boolean> }> {
        try {
          this.isLoading = true;
          let authToken;

          if (!process.server) {
            authToken = localStorage.getItem("authToken");
          }

          const { pending } = await useFetch<number>(
            `${config("apiBaseUrl")}/opportunity-submission/by-opportunity/${
              this.opportunity._id
            }/count`,
            {
              params: query,
              key: `opportunity-submission/by-opportunity/${
                this.opportunity._id
              }/count/${JSON.stringify(query.value)}`,
              watch: [() => query],
              onResponse: ({ response }) => {
                this.tmpOpportunitySubmissionsCount = response._data || 0;
              },
              headers: {
                ...(authToken && { Authorization: `Bearer ${authToken}` }),
              },
            },
          );

          return { pending };
        } catch (error) {
          throw handleError(error);
        } finally {
          this.isLoading = false;
        }
      },

      getNext(currentId: string): OpportunitySubmission | undefined {
        const index = this.opportunitySubmissions.findIndex(
          (opportunitySubmission) => opportunitySubmission._id === currentId,
        );
        return this.opportunitySubmissions[index + 1];
      },

      async checkIfAlreadySubmitted(
        trackId: string,
      ): Promise<{ submitted: boolean }> {
        return opportunitySubmissionService.checkIfAlreadySubmitted(
          this.opportunity._id,
          trackId,
        );
      },
    },
    persist: true,
  },
);
