import { Opportunity } from "@/types/opportunity";
import BaseService from "@/services/base-service";
import { OpportunitySubmission } from "../types/opportunity-submission";
import { ProAction } from "@/types/playlists";
import { Sort } from "@/types/data-table";
import { getGAVariables } from "@/utils/helpers";
import { handleError } from "@/utils/error";

class OpportunitySubmissionService extends BaseService {
  constructor(_rootEndpoint: string) {
    super(_rootEndpoint);
  }

  public async getBySlug(slug: string): Promise<Opportunity> {
    try {
      return await this.get(`${this.getServiceContext}/slug/${slug}`);
    } catch (error) {
      throw handleError(error);
    }
  }

  public async listMyOpportunitySubmissions(
    pagination: {
      start: number;
      length: number;
    },
    sort?: Sort,
  ): Promise<{ total: number; data: OpportunitySubmission[] }> {
    try {
      return await this.get(
        `${this.getServiceContext}/?start=${pagination.start}&length=${
          pagination.length
        }${sort?.column ? "&column=" + sort?.column : ""}${
          sort?.order ? "&order=" + sort?.order : ""
        }`,
      );
    } catch (error) {
      throw handleError(error);
    }
  }

  public async listByOpportunityId(
    opportunityId: string,
    // pagination: { start: number; length: number; filter?: string },
    filters: { [key: string]: string },
  ): Promise<OpportunitySubmission[]> {
    try {
      return await this.get(
        `${
          this.getServiceContext
        }/by-opportunity/${opportunityId}?${Object.keys(filters)
          .filter((key) => key.length > 0)
          .map((key) => `${key}=${filters[key]}`)
          .join("&")}`,
      );
    } catch (error) {
      throw handleError(error);
    }
  }

  public async getCountByOpportunityId(
    opportunityId: string,
    filters: { [key: string]: string },
  ): Promise<number> {
    try {
      return await this.get(
        `${
          this.getServiceContext
        }/by-opportunity/${opportunityId}/count?${Object.keys(filters)
          .filter((key) => key.length > 0)
          .map((key) => `${key}=${filters[key]}`)
          .join("&")}`,
      );
    } catch (error) {
      throw handleError(error);
    }
  }

  public async getById(id: string): Promise<OpportunitySubmission> {
    try {
      return await this.get(`${this.getServiceContext}/${id}`);
    } catch (error) {
      throw handleError(error);
    }
  }

  public async proAction(id: string, proAction: ProAction, progress: number) {
    try {
      return await this.post(`${this.getServiceContext}/${id}/action`, {
        proAction,
        progress: Math.floor(progress) || 0,
      });
    } catch (error) {
      throw handleError(error);
    }
  }

  public async submitOpportunity(
    data: OpportunitySubmission,
  ): Promise<OpportunitySubmission> {
    try {
      const analytics = await getGAVariables();
      return await this.post(`${this.getServiceContext}`, {
        ...data,
        analytics,
      });
    } catch (error) {
      throw handleError(error);
    }
  }

  public async submitMessage(
    id: string,
    message: string,
    progress: number,
  ): Promise<{ ok: boolean }> {
    try {
      return await this.post(`${this.getServiceContext}/${id}/message`, {
        message,
        progress: Math.floor(progress),
      });
    } catch (error) {
      throw handleError(error);
    }
  }

  public async checkIfAlreadySubmitted(
    id: string,
    trackId: string,
  ): Promise<{ submitted: boolean }> {
    try {
      return await this.get(
        `${this.getServiceContext}/${id}/can-submit?trackId=${trackId}`,
      );
    } catch (e) {
      throw handleError(e);
    }
  }
}

export default OpportunitySubmissionService;
